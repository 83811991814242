import { loadCandidateResume } from '@/client/redux/actions';
import {
  applyToJobisSubmittionSelector,
  userOnboardingFinishedVerifiedSelector,
} from '@/client/redux/selectors';
import { setApplicant, setSubmitted } from '@/client/redux/store/apply-to-job.slice';
import { Theme, useMediaQuery } from '@material-ui/core';
import { MouseEvent, useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { IUseApplyToJobModalProps } from './types';

const useApplyToJobModal = ({
  jobId,
  jobSelector,
  onClose,
  onSuccess,
}: IUseApplyToJobModalProps) => {
  const job = useSelector(jobSelector(jobId));
  const isOnboardingFinished = useSelector(userOnboardingFinishedVerifiedSelector);
  const { isSubmited, annualRate } = useSelector(applyToJobisSubmittionSelector);
  const isDesktop = useMediaQuery((theme: Theme) => theme.breakpoints.up('sm'));
  const dispatch = useDispatch();

  const onModalClose = useCallback(
    (e: MouseEvent<HTMLButtonElement>) => {
      dispatch(setSubmitted(false));
      dispatch(setApplicant(null));
      onClose(e);
    },
    [dispatch, onClose],
  );

  const successHandler = useCallback(
    (e: MouseEvent<HTMLButtonElement>) => {
      onModalClose(e);
      onSuccess?.();
    },
    [onModalClose, onSuccess],
  );

  useEffect(() => {
    if (isOnboardingFinished) {
      // Load resume file to use as default in Apply form
      dispatch(loadCandidateResume());
    }
  }, [dispatch, isOnboardingFinished]);

  return {
    isSubmited,
    isDesktop,
    job,
    annualRate,
    onModalClose,
    successHandler,
  };
};

export default useApplyToJobModal;
