import { IconButton, Typography } from '@material-ui/core';
import Image from 'next/image';
import clsx from 'clsx';
import { Button } from '@/client/components/Button';
import ISuccessProps from './types';
import useStyles from './styles';

const SuccessModalView = ({
  title,
  successBtnText,
  imageSrc = '/images/success-modal.svg',
  children,
  onClose,
  onDone,
}: ISuccessProps) => {
  const classes = useStyles();

  return (
    <>
      <IconButton disableRipple disableFocusRipple onClick={onClose} className={classes.closeBtn}>
        <Image src="/icons/close-icon.svg" width={18} height={18} />
      </IconButton>
      <div className={classes.content}>
        <div className={classes.image}>
          <Image src={imageSrc} width={310} height={186} />
        </div>
        {title && (
          <Typography variant="h1" component="h1" className={classes.title}>
            {title}
          </Typography>
        )}
        {children}
      </div>
      <div className={classes.actions}>
        <Button className={clsx(classes.btn)} onClick={onDone}>
          {successBtnText ?? 'Done'}
        </Button>
      </div>
    </>
  );
};

export default SuccessModalView;
