import { SuccessModalView } from '@/client/components/Modals';
import { Modal } from '@/client/components';
import { browseJobByIdSelector } from '@/client/redux/selectors';
import { Typography } from '@material-ui/core';
import { useMemo } from 'react';
import ApplyFormView from './components/ApplyFormView';
import useStyles from './styles';
import { IApplyToJobModalProps } from './types';
import useApplyToJobModal from './useApplyToJobModal';

const ApplyToJobModal = ({
  jobId,
  onClose,
  onSuccess,
  jobSelector = browseJobByIdSelector,
}: IApplyToJobModalProps) => {
  const classes = useStyles();
  const { isSubmited, isDesktop, job, onModalClose, successHandler } = useApplyToJobModal({
    jobId,
    onClose,
    onSuccess,
    jobSelector,
  });

  const renderCurrentStep = useMemo(() => {
    if (!job) {
      return null;
    }

    return isSubmited ? (
      <SuccessModalView
        onClose={successHandler}
        onDone={successHandler}
        title="You’ve successfully applied!"
      >
        <Typography variant="body1" component="p" className={classes.text}>
          Your application has been submitted to {job.companyInfoName}.
        </Typography>
      </SuccessModalView>
    ) : (
      <ApplyFormView onClose={onModalClose} job={job} isDesktop={isDesktop} />
    );
  }, [job, isSubmited, onModalClose, classes.text, isDesktop, successHandler]);

  return (
    <Modal
      open={!!jobId}
      onClose={isSubmited ? successHandler : onModalClose}
      classes={{ paper: classes.paper }}
    >
      {renderCurrentStep}
    </Modal>
  );
};

export default ApplyToJobModal;
