import { useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setHeaderCenteredLayout } from '@/client/redux/store/settings.slice';
import {
  candidateDefaultResumeUriSelector,
  applyToJobIsLoadingSelector,
} from '@/client/redux/selectors';
import { IApplyToJobPayload } from '@/client/redux/types';
import { applyToJob } from '@/client/redux/actions';
import { useFormik } from 'formik';
import { applyForAJobSchema } from '@/validations';
import { IUseApplyFormViewProps } from '../../types';

const useApplyFormView = ({ jobId, onClose }: IUseApplyFormViewProps) => {
  const defaultResumeUri = useSelector(candidateDefaultResumeUriSelector);
  const isLoading = useSelector(applyToJobIsLoadingSelector);
  const dispatch = useDispatch();

  const onSubmit = useCallback(
    (values: IApplyToJobPayload) => {
      dispatch(applyToJob(values));
    },
    [dispatch],
  );

  const formik = useFormik<IApplyToJobPayload>({
    validationSchema: applyForAJobSchema,
    enableReinitialize: true,
    initialValues: {
      jobId,
      message: '',
      file: undefined,
    },
    onSubmit,
  });

  const onConfirm = useCallback(() => {
    formik.submitForm();
  }, [formik]);

  const handleChangeResume = useCallback(
    (e) => {
      formik.setFieldTouched('file', true);
      formik.setFieldValue('file', e.target.files?.[0]);
    },
    [formik],
  );

  const handleDeleteResume = useCallback(() => formik.setFieldValue('file', undefined), [formik]);

  useEffect(() => {
    dispatch(
      setHeaderCenteredLayout({
        onBack: onClose,
      }),
    );
    return () => {
      dispatch(setHeaderCenteredLayout({ onBack: null }));
    };
  }, [dispatch, onClose]);

  return {
    isLoading,
    defaultResumeUri,
    formik,
    onConfirm,
    handleChangeResume,
    handleDeleteResume,
  };
};

export default useApplyFormView;
