import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  wrapper: {
    width: '100%',
    justifyContent: 'flex-start',
    marginBottom: theme.spacing(5),
    color: theme.palette.text.primary,
    [theme.breakpoints.up('sm')]: {
      fontSize: 14,
      lineHeight: '20px',
    },
  },
  uploadLabel: {
    fontSize: theme.typography.fonts.size.sm,
    lineHeight: theme.typography.fonts.lineHeight.sm,
    textTransform: 'none',
    [theme.breakpoints.up('sm')]: {
      fontSize: 14,
      lineHeight: '20px',
    },
  },
  labelWrapper: {
    display: 'flex',
    alignItems: 'center',
    fontWeight: 500,
    fontSize: theme.typography.fonts.size.md,
    lineHeight: theme.typography.fonts.lineHeight.md,
    margin: theme.spacing(4, 0, 5, 0),
    color: theme.palette.text.primary,
    [theme.breakpoints.up('sm')]: {
      fontSize: theme.typography.fonts.size.sm,
      lineHeight: theme.typography.fonts.lineHeight.sm,
    },
  },
  icon: {
    display: 'flex',
    width: theme.spacing(75),
    height: theme.spacing(18),
    justifyContent: 'center',
    alignItems: 'center',
    marginRight: theme.spacing(4),
    border: `1px solid ${theme.palette.blackMain}`,
    borderRadius: theme.spacing(10),
    overflow: 'hidden',
    [theme.breakpoints.up('sm')]: {
      marginRight: theme.spacing(8),
    },
  },
  tooltipInfo: {
    paddingLeft: 2,
    '& > .MuiButton-root': {
      padding: 0,
    },
  },
  avatar: {
    width: theme.spacing(7),
    height: theme.spacing(8),
    marginRight: theme.spacing(2),
  },
  uploadedFile: {
    display: 'flex',
    alignItems: 'center',
    lineHeight: '33px',
  },
}));

export default useStyles;
