import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { setHeaderFixedCenteredLayout } from '@/client/redux/store/settings.slice';
import { Theme, useMediaQuery } from '@material-ui/core';
import { IModalProps } from './types';

export default function useModal({ open, onClose }: Omit<IModalProps, 'children'>) {
  const dispatch = useDispatch();
  const isDesktop = useMediaQuery((theme: Theme) => theme.breakpoints.up('sm'));

  useEffect(() => {
    if (!open) {
      return;
    }
    dispatch(
      setHeaderFixedCenteredLayout({
        isHeaderFixed: true,
        onBack: onClose,
      }),
    );
    return () => {
      dispatch(
        setHeaderFixedCenteredLayout({
          isHeaderFixed: false,
        }),
      );
    };
  }, [dispatch, open, onClose]);

  return {
    isDesktop,
  };
}
