import { makeStyles } from '@material-ui/core';
import { headerHeight } from '@/client/components';

const useStyles = makeStyles((theme) => ({
  title: {
    fontSize: '28px',
    fontWeight: 600,
    lineHeight: '35px',
    textAlign: 'center',
    [theme.breakpoints.up('sm')]: {
      fontSize: '28px',
      lineHeight: '35px',
    },
  },
  backBtn: {
    position: 'absolute',
    top: -headerHeight.mobile / 2,
    left: theme.spacing(20),
    transform: 'translateY(100%)',
    [theme.breakpoints.up('sm')]: {
      display: 'none',
    },
  },
  aboutContainer: {
    display: 'flex',
    alignItems: 'center',
    margin: theme.spacing(11, 0, 9, 0),
    [theme.breakpoints.up('sm')]: {
      margin: theme.spacing(19, 0, 12, 0),
    },
  },
  jobTitle: {
    fontSize: 16,
    fontWeight: 500,
    lineHeight: '22px',
    marginBottom: theme.spacing(1),
    [theme.breakpoints.up('sm')]: {
      fontWeight: 600,
      fontSize: 18,
      lineHeight: '30px',
    },
  },
  companyTitle: {
    fontSize: 12,
    lineHeight: '18px',
    [theme.breakpoints.up('sm')]: {
      fontSize: 14,
      lineHeight: '22px',
    },
  },
  avatar: {
    marginRight: theme.spacing(4),
    width: 44,
    height: 44,
    [theme.breakpoints.up('sm')]: {
      border: `1px solid ${theme.palette.border}`,
      marginRight: theme.spacing(6),
      width: 70,
      height: 70,
    },
  },
  message: {
    marginBottom: 0,
  },
  uploadWrapper: {
    '& > div:first-child': {
      display: 'none',
    },
    '& > div': {
      color: theme.palette.primary.main,
    },
    '& > label': {
      '& > div': {
        border: 'none',
        width: 'initial',
        marginRight: theme.spacing(4),
      },
      '& > p': {
        color: theme.palette.text.secondary,
      },
    },
  },
  actions: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: theme.spacing(10),
    '& $btn:not(:last-of-type)': {
      marginRight: theme.spacing(5),
    },
    [theme.breakpoints.up('sm')]: {
      marginTop: theme.spacing(15),
      '& $btn:not(:last-of-type)': {
        marginRight: theme.spacing(8),
      },
    },
    [theme.breakpoints.up('md')]: {
      marginTop: theme.spacing(20),
    },
  },
  btn: {
    width: '100%',
    height: theme.spacing(20),
    fontWeight: 500,
    fontSize: 14,
    lineHeight: '20px',
    letterSpacing: '0.0016em',
    [theme.breakpoints.up('sm')]: {
      width: 'auto',
      minWidth: 133,
    },
  },
}));

export default useStyles;
