import { createSelector } from 'reselect';
import { IState } from '../types';

export const applyToJobSelector = ({ applyToJob }: IState) => applyToJob;

export const applyToJobIsLoadingSelector = createSelector(
  applyToJobSelector,
  ({ isLoading }) => isLoading,
);

export const applyToJobisSubmittionSelector = createSelector(
  applyToJobSelector,
  ({ isSubmited, applicant }) => ({ isSubmited, annualRate: applicant?.candidateRate }),
);
