import { useState, useCallback } from 'react';

const useInputField = () => {
  const [open, setOpen] = useState<boolean>(false);

  const toggleOpen = useCallback(() => setOpen(!open), [open]);

  return {
    open,
    toggleOpen,
  };
};

export default useInputField;
