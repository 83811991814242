import React from 'react';
import Image from 'next/image';
import clsx from 'clsx';
import { TextField, FormControl, FormHelperText } from '@material-ui/core';
import { TooltipInfo } from '@/client/components';
import useStyles from './styles';
import { ITextFieldProps } from './types';
import useInputField from './useInputField';

export const InputField = ({
  disabled = false,
  InputProps,
  className,
  name,
  label,
  labelInfo,
  variant = 'outlined',
  multiline = false,
  Example,
  helperText,
  error,
  required = false,
  ...rest
}: ITextFieldProps): JSX.Element => {
  const classes = useStyles();
  const { open, toggleOpen } = useInputField();

  return (
    <FormControl variant="outlined" className={clsx(classes.wrapper, className)}>
      <div className={clsx(classes.label, { [classes.asterisk]: required })}>
        {label}
        {labelInfo && <TooltipInfo title={labelInfo} />}
      </div>
      <TextField
        id={name}
        className={clsx(classes.wrapper, Example && classes.noMargin, className)}
        disabled={disabled}
        fullWidth
        variant={variant}
        multiline={multiline}
        InputProps={{
          className: clsx(
            InputProps?.className,
            classes.input,
            multiline && classes.multiline,
            open && classes.inputBorder,
          ),
          autoComplete: InputProps?.autoComplete ?? 'new-password',
          ...InputProps,
        }}
        InputLabelProps={{
          shrink: true,
          className: classes.label,
        }}
        name={name}
        error={error}
        {...rest}
      />
      {Example && (
        <>
          {open && <div className={classes.exampleWrapper}>{Example}</div>}
          <div onClick={toggleOpen} role="presentation" className={classes.exampleBtn}>
            Example
            <span className={classes.chevronIcon}>
              <Image
                src={open ? '/icons/chevron-up-icon.svg' : '/icons/chevron-down-icon.svg'}
                width={9}
                height={5}
              />
            </span>
          </div>
        </>
      )}
      <FormHelperText error={error} className={clsx({ [classes.helperTextWithExample]: Example })}>
        {helperText}
      </FormHelperText>
    </FormControl>
  );
};

export default InputField;
