import Image from 'next/image';
import { Tooltip, Button, ClickAwayListener } from '@material-ui/core';
import useTooltipInfo from './useTooltipInfo';
import useStyles from './styles';
import { ITooltipInfoProps } from './types';

export const TooltipInfo = ({
  title,
  className,
  stopPropagation,
  ...props
}: ITooltipInfoProps): JSX.Element => {
  const classes = useStyles();

  const { open, handleTooltipClose, handleTooltipOpen, handleStopPropagation } =
    useTooltipInfo(stopPropagation);

  return (
    <ClickAwayListener onClickAway={handleTooltipClose}>
      <Tooltip
        onClose={handleTooltipClose}
        onClick={handleStopPropagation}
        open={open}
        title={title}
        className={className}
        classes={{ popper: classes.popper }}
        arrow
        {...props}
      >
        <span>
          <Button
            onMouseOver={handleTooltipOpen}
            onClick={handleTooltipOpen}
            className={classes.tooltip}
          >
            <Image src="/icons/question-sign-icon.svg" width={17} height={17} />
          </Button>
        </span>
      </Tooltip>
    </ClickAwayListener>
  );
};

export default TooltipInfo;
