import { createSelector } from 'reselect';
import { IState } from '../types';

export const profileSelector = ({ profile }: IState) => profile;
export const profileCitiesSelector = createSelector(profileSelector, (state) => state.cities);
export const profileRegionsSelector = createSelector(profileSelector, (state) => state.regions);
export const profileLoadingSelector = createSelector(profileSelector, (state) => state.loading);
export const profileFetchingSelector = createSelector(profileSelector, (state) => state.fetching);

export const profileCandidateSelector = createSelector(profileSelector, (state) => state.candidate);
export const profileCandidateBasicInfoSelector = createSelector(
  profileSelector,
  (state) => state.candidate?.baseInfo,
);
export const candidateDefaultResumeUriSelector = createSelector(
  profileSelector,
  (state) => state.resumeUri,
);

export const resetPasswordErrorsSelector = createSelector(profileSelector, (state) => state.errors);
