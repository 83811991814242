import { Theme, makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) => ({
  title: {
    marginTop: theme.spacing(16),
    fontSize: '28px',
    fontWeight: 600,
    lineHeight: '35px',
    textAlign: 'center',
    [theme.breakpoints.up('sm')]: {
      fontSize: '28px',
      lineHeight: '35px',
    },
  },
  text: {
    marginTop: theme.spacing(6),
    fontSize: '14px',
    lineHeight: '20px',
    textAlign: 'center',
    color: theme.palette.secondaryBlack,
    '&:first-of-type': {
      marginTop: theme.spacing(8),
    },
    [theme.breakpoints.up('sm')]: {
      fontSize: '16px',
      lineHeight: '25px',
    },
  },
  image: {
    width: theme.spacing(83),
    margin: theme.spacing(0, 'auto'),
    padding: theme.spacing(15, 0, 10, 0),
    [theme.breakpoints.up('sm')]: {
      padding: theme.spacing(31, 0, 16, 0),
      width: theme.spacing(155),
    },
  },
  content: {
    marginTop: theme.spacing(5),
    [theme.breakpoints.up('sm')]: {
      margin: 0,
    },
  },
  actions: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: theme.spacing(16),
    '& $btn:not(:last-of-type)': {
      marginRight: theme.spacing(5),
    },
    [theme.breakpoints.up('sm')]: {
      '& $btn:not(:last-of-type)': {
        marginRight: theme.spacing(8),
      },
    },
  },
  btn: {
    minWidth: theme.spacing(66),
    width: '100%',
    height: theme.spacing(20),
    fontWeight: 500,
    fontSize: '14px',
    lineHeight: '20px',
    letterSpacing: '0.0016em',
    [theme.breakpoints.up('sm')]: {
      width: 'auto',
    },
  },
  closeBtn: {
    display: 'none',
    [theme.breakpoints.up('sm')]: {
      display: 'block',
      position: 'absolute',
      top: 28,
      right: 28,
    },
  },
}));
export default useStyles;
