import { Modal as MuiModal } from '@material-ui/core';
import clsx from 'clsx';
import { IModalProps } from './types';
import useModal from './useModal';
import useStyles from './styles';

const Modal = ({ open, onClose, children, classes, ...rest }: IModalProps) => {
  const internalClasses = useStyles();
  const { isDesktop } = useModal({ open, onClose });

  return (
    <MuiModal
      open={open}
      onClose={onClose}
      hideBackdrop={!isDesktop}
      className={classes?.root}
      {...rest}
    >
      <div className={clsx(internalClasses.paper, classes?.paper)}>{children}</div>
    </MuiModal>
  );
};

export default Modal;
