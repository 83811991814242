import { makeStyles, Theme } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) => ({
  paper: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    padding: theme.spacing(15, 8),
    [theme.breakpoints.up('sm')]: {
      borderRadius: theme.spacing(4),
      padding: theme.spacing(15, 14),
    },
    [theme.breakpoints.up('lg')]: {
      padding: theme.spacing(25),
    },
  },
  text: {
    fontSize: 14,
    lineHeight: '20px',
    textAlign: 'center',
    color: '#83879C',
    letterSpacing: '0.0016em',
    marginTop: theme.spacing(8),
    '&:first-child': {
      marginTop: theme.spacing(10),
    },
    '& > b': {
      color: theme.palette.text.primary,
    },
    [theme.breakpoints.up('sm')]: {
      fontSize: 16,
      lineHeight: '25px',
      marginTop: theme.spacing(6),
      '&:first-child': {
        marginTop: theme.spacing(8),
      },
    },
  },
}));
export default useStyles;
