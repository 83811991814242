import { Theme, makeStyles } from '@material-ui/core';

import { headerHeight } from '@/client/components';

const useStyles = makeStyles((theme: Theme) => ({
  paper: {
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: theme.palette.background.paper,
    width: '100%',
    height: `calc(100% - ${headerHeight.mobile}px)`,
    marginTop: headerHeight.mobile,
    padding: theme.spacing(10, 10, 20),
    overflowY: 'auto',
    outline: 'none',
    [theme.breakpoints.up('sm')]: {
      display: 'block',
      position: 'absolute',
      borderRadius: theme.spacing(4),
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      maxWidth: 570,
      marginTop: 0,
      padding: theme.spacing(20, 25, 25),
      height: 'auto',
      maxHeight: '90vh',
    },
  },
}));
export default useStyles;
