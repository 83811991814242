import { useCallback, useState } from 'react';

const useTooltipInfo = (stopPropagation?: boolean) => {
  const [open, setOpen] = useState(false);

  const handleTooltipClose = () => {
    setOpen(false);
  };

  const handleTooltipOpen = () => {
    setOpen(true);
  };

  const handleStopPropagation = useCallback(
    (e) => {
      if (stopPropagation) {
        e.stopPropagation();
      }
    },
    [stopPropagation],
  );

  return { open, handleTooltipClose, handleTooltipOpen, handleStopPropagation };
};

export default useTooltipInfo;
