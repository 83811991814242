import { Typography, IconButton, Avatar } from '@material-ui/core';
import { Button, InputField } from '@/client/components';
import Image from 'next/image';
import ResumeUpload from '@/client/components/ResumeUpload';
import { getSmallImageUri } from '@/client/utils';
import useStyles from './styles';
import { IApplyFormViewProps } from '../../types';
import useApplyFormView from './useApplyFormView';

const ApplyFormView = ({ job, isDesktop, onClose }: IApplyFormViewProps) => {
  const classes = useStyles();
  const { isLoading, defaultResumeUri, formik, onConfirm, handleChangeResume, handleDeleteResume } =
    useApplyFormView({ jobId: job.id, onClose });

  return (
    <>
      <IconButton disableRipple disableFocusRipple onClick={onClose} className={classes.backBtn}>
        <Image src="/icons/chevron-left.svg" width={18} height={18} />
      </IconButton>
      <Typography variant="h4" component="h4" className={classes.title}>
        Apply to job
      </Typography>
      <div>
        <div className={classes.aboutContainer}>
          <Avatar
            src={
              job.companyInfoLogo
                ? getSmallImageUri(job.companyInfoLogo)
                : '/images/default-logo.svg'
            }
            className={classes.avatar}
          />
          <div>
            <Typography variant="h5" component="h5" className={classes.jobTitle}>
              {job.jobTitle}
            </Typography>
            <Typography className={classes.companyTitle}>{job.companyInfoName}</Typography>
          </div>
        </div>
        <form>
          <InputField
            label="Highlight any past experiences that make you a great fit for this role"
            multiline
            rows={7}
            name="message"
            className={classes.message}
            value={formik.values.message}
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
            error={!!formik.errors.message && formik.touched.message}
            helperText={formik.touched.message && formik.errors.message}
            required
          />
          <ResumeUpload
            uploadedResume={formik.values.file}
            defaultResumeUri={defaultResumeUri}
            onChange={handleChangeResume}
            canDelete={!!formik.values.file}
            onDelete={handleDeleteResume}
            placeholder="DOC, DOCX, PDF (8 MB max)"
            className={classes.uploadWrapper}
            error={!!formik.errors.file && formik.touched.file}
            helperText={formik.touched.file && formik.errors.file ? formik.errors.file : undefined}
          />
        </form>
      </div>
      <div className={classes.actions}>
        <Button disabled={isLoading} className={classes.btn} onClick={onConfirm}>
          {isDesktop ? 'Apply' : 'Continue'}
        </Button>
        {isDesktop && (
          <Button disabled={isLoading} variant="outlined" className={classes.btn} onClick={onClose}>
            Cancel
          </Button>
        )}
      </div>
    </>
  );
};

export default ApplyFormView;
