import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  tooltip: {
    minWidth: 'auto',
    cursor: 'default',
  },
  popper: {
    '& .MuiTooltip-tooltip': {
      backgroundColor: theme.palette.text.primary,
      padding: theme.spacing(4),
    },
    '& .MuiTooltip-arrow': {
      color: theme.palette.text.primary,
    },
  },
}));

export default useStyles;
