import { IApplyJobRequest, IDeclinedApplicantRequest } from '@/requests';
import { object, SchemaOf, string } from 'yup';

export const applyForAJobSchema: SchemaOf<IApplyJobRequest> = object().shape({
  jobId: string().trim().required().uuid(),
  message: string().trim().required().min(10).max(2000),
});

export const declineSchema: SchemaOf<IDeclinedApplicantRequest> = object().shape({
  applicantId: string().trim().required().uuid(),
});
