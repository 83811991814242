import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  wrapper: {
    position: 'relative',
    width: '100%',
    marginBottom: theme.spacing(4),
    marginTop: theme.spacing(4),
    '& .Mui-error fieldset': {
      borderRadius: theme.spacing(4),
    },
  },
  noMargin: {
    marginBottom: theme.spacing(0),
  },
  label: {
    display: 'flex',
    alignItems: 'center',
    position: 'relative',
    maxWidth: '100%',
    fontWeight: 500,
    fontSize: theme.typography.fonts.size.md,
    lineHeight: 1,
    transform: 'none !important',
    color: theme.palette.text.primary,
    '&.Mui-focused, &.Mui-error': {
      color: theme.palette.text.primary,
    },
    [theme.breakpoints.up('sm')]: {
      fontSize: theme.typography.fonts.size.sm,
      lineHeight: theme.typography.fonts.lineHeight.sm,
    },
  },
  asterisk: {
    '&::after': {
      content: '"*"',
      color: theme.palette.error.main,
      marginLeft: theme.spacing(2.5),
      fontWeight: 500,
      fontSize: theme.typography.fonts.size.md,
      lineHeight: 1,
      [theme.breakpoints.up('sm')]: {
        fontSize: '14px',
        lineHeight: '17px',
      },
    },
  },
  input: {
    padding: theme.spacing(0),
    height: 44,
    fontSize: theme.typography.fonts.size.md,
    lineHeight: theme.typography.fonts.lineHeight.md,
    '&.MuiOutlinedInput-adornedEnd': {
      paddingRight: theme.spacing(7),
    },
    '& input': {
      padding: theme.spacing(6),
      '&:-webkit-autofill': {
        borderRadius: theme.spacing(4),
      },
    },
    '& fieldset': {
      border: '1px solid',
      padding: theme.spacing(7),
      borderRadius: theme.spacing(4),
      borderColor: theme.palette.secondary.dark,
      height: 44,
      top: 0,
      '& legend': {
        display: 'none',
      },
    },
    '&::before, &::after': {
      content: 'none',
    },
    '&::placeholder': {
      color: theme.palette.secondary.main,
      opacity: 1,
    },
    '&:hover fieldset': {
      '&.MuiOutlinedInput-notchedOutline': {
        borderColor: theme.palette.secondary.dark,
      },
    },
    '&.Mui-focused fieldset, &.Mui-focused:hover fieldset': {
      '&.MuiOutlinedInput-notchedOutline': {
        border: '1px solid',
        borderColor: theme.palette.text.secondary,
      },
    },
    [theme.breakpoints.up('sm')]: {
      fontSize: '14px',
      lineHeight: '20px',
    },
  },
  inputBorder: {
    '& fieldset': {
      borderRadius: theme.spacing(4, 4, 0, 0),
    },
  },
  multiline: {
    padding: theme.spacing(7),
    height: 'auto',
    '& fieldset': {
      height: 'auto',
    },
  },
  exampleBtn: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    background: theme.palette.secondary.dark,
    width: theme.spacing(58),
    marginLeft: 'auto',
    marginRight: theme.spacing(4),
    fontSize: theme.typography.fonts.size.md,
    color: theme.palette.text.secondary,
    lineHeight: theme.typography.fonts.lineHeight.md,
    padding: theme.spacing(1),
    clipPath: 'inset(0 0 0 round 0 0 5px 5px)',
  },
  helperTextWithExample: {
    marginTop: -theme.spacing(9),
    marginRight: 132,
  },
  chevronIcon: {
    marginLeft: theme.spacing(2),
  },
  exampleWrapper: {
    border: `1px solid ${theme.palette.secondary.dark}`,
    boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.06)',
    borderRadius: theme.spacing(0, 0, 4, 4),
    padding: theme.spacing(9),
    marginTop: theme.spacing(-0.5),
    maxHeight: '138px',
    overflowY: 'scroll',
  },
}));

export default useStyles;
