import clsx from 'clsx';
import Image from 'next/image';
import { ButtonBase, Typography, Avatar, IconButton, FormHelperText } from '@material-ui/core';
import { useMemo } from 'react';
import { createFileNameFromUri } from '@/client/utils';
import { TooltipInfo } from '../TooltipInfo';
import useStyles from './styles';
import { IResumeInputProps } from './types';

export const ResumeUpload = ({
  label,
  labelInfo,
  className,
  placeholder,
  uploadedResume,
  defaultResumeUri,
  canDelete = true,
  onDelete,
  error,
  helperText,
  ...rest
}: IResumeInputProps): JSX.Element => {
  const { wrapper, uploadLabel, icon, tooltipInfo, avatar, labelWrapper, uploadedFile } =
    useStyles();

  const fileName = useMemo(() => {
    if (uploadedResume) {
      return uploadedResume;
    }
    if (defaultResumeUri) {
      return createFileNameFromUri(defaultResumeUri);
    }

    return null;
  }, [defaultResumeUri, uploadedResume]);

  return (
    <div className={clsx(wrapper, className)}>
      <div className={labelWrapper}>
        {label}
        {labelInfo && <TooltipInfo className={tooltipInfo} title={labelInfo} />}
      </div>
      <ButtonBase component="label" disableRipple>
        <div className={icon}>
          <Avatar src="/icons/resume-button-icon.svg" alt="" className={avatar} />
          {fileName ? 'Update resume' : 'Upload resume'}
        </div>
        {placeholder && (
          <Typography variant="body1" className={uploadLabel}>
            {placeholder}
          </Typography>
        )}
        <input accept=".doc,.docx,.pdf" type="file" value="" hidden {...rest} />
      </ButtonBase>
      {fileName && (
        <div className={uploadedFile}>
          {fileName.name}
          {canDelete && (
            <IconButton aria-label="delete" onClick={onDelete}>
              <Image src="/icons/close-icon.svg" width={9} height={9} />
            </IconButton>
          )}
        </div>
      )}
      <FormHelperText error={error}>{helperText}</FormHelperText>
    </div>
  );
};

export default ResumeUpload;
